import React from 'react';
import {
    CardItemSection,
    CardItemSectionProps,
} from '@solublestudio/legalbrave-design-system';

export default function BenefitsSection({
    cards,
    ...props
}: CardItemSectionProps) {
    const parseCards = cards.filter(({ title, text }) => title || text);
    return <CardItemSection {...props} cards={parseCards} />;
}
